export const menu = [
    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Pagine'
    },
    {
        "type": 'item',
        "href": '/',
        "icon": 'home',
        "entity": '',
        "label": 'Dashboard'
    },
    {
        "type": 'item',
        "href": '/entity/auth_token',
        "icon": 'home',
        "entity": 'auth_token',
        "label": 'auth_token'
    },
    {
        "type": 'item',
        "href": '/entity/auth_pin',
        "icon": 'plus',
        "entity": 'auth_pin',
        "label": 'auth_pin'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_alert_quotazione',
        "icon": 'plus',
        "entity": 'gyv_alert_quotazione',
        "label": 'gyv_alert_quotazione'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_anagrafica_conto_invio_a20',
        "icon": 'plus',
        "entity": 'gyv_anagrafica_conto_invio_a20',
        "label": 'gyv_anagrafica_conto_invio_a20'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_anagrafica_conto_invita_amico',
        "icon": 'plus',
        "entity": 'gyv_anagrafica_conto_invita_amico',
        "label": 'gyv_anagrafica_conto_invita_amico'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_anagrafica_conto_rinnovo',
        "icon": 'plus',
        "entity": 'gyv_anagrafica_conto_rinnovo',
        "label": 'gyv_anagrafica_conto_rinnovo'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_coupon_conto',
        "icon": 'plus',
        "entity": 'gyv_coupon_conto',
        "label": 'gyv_coupon_conto'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_deposito',
        "icon": 'plus',
        "entity": 'gyv_deposito',
        "label": 'gyv_deposito'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_deposito_periodico',
        "icon": 'plus',
        "entity": 'gyv_deposito_periodico',
        "label": 'gyv_deposito_periodico'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_deposito_periodico_frequency',
        "icon": 'plus',
        "entity": 'gyv_deposito_periodico_frequency',
        "label": 'gyv_deposito_periodico_frequency'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_deposito_periodico_item',
        "icon": 'plus',
        "entity": 'gyv_deposito_periodico_item',
        "label": 'gyv_deposito_periodico_item'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_deposito_rows',
        "icon": 'plus',
        "entity": 'gyv_deposito_rows',
        "label": 'gyv_deposito_rows'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_deposito_rows_operation',
        "icon": 'plus',
        "entity": 'gyv_deposito_rows_operation',
        "label": 'gyv_deposito_rows_operation'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_procacciatori',
        "icon": 'plus',
        "entity": 'gyv_procacciatori',
        "label": 'gyv_procacciatori'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_quotazioni',
        "icon": 'plus',
        "entity": 'gyv_quotazioni',
        "label": 'gyv_quotazioni'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_tipo_quotazione',
        "icon": 'plus',
        "entity": 'gyv_tipo_quotazione',
        "label": 'gyv_tipo_quotazione'
    },
    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Impostazioni'
    },
    {
        "type": 'item',
        "href": '/settings',
        "icon": 'user-gear',
        "entity": '',
        "label": 'Profilo'
    },
    {
        "type": 'item',
        "href": '/entity/user',
        "icon": 'user',
        "entity": 'user',
        "label": 'Utenti'
    },
    {
        "type": 'item',
        "href": '/entity/user_group',
        "icon": 'users',
        "entity": 'user_group',
        "label": 'Gruppi'
    },
    {
        "type": 'item',
        "href": '/entity/user_entity_saved_filters',
        "icon": 'save',
        "entity": 'user_entity_saved_filters',
        "label": 'Filtri salvati'
    },
    {
        "type": 'item',
        "href": '/entity/user_entity_preferences',
        "icon": 'cogs',
        "entity": 'user_entity_preferences',
        "label": 'Preferenze entità'
    },
    {
        "type": 'item',
        "href": '/logout',
        "icon": 'arrow-right-from-bracket',
        "entity": '',
        "label": 'Logout'
    },
]