export const entity_language = {
    entity: {
        "user": 'Utenti',
        "user_group": 'Gruppi',
        "user_entity_saved_filters": 'Filtri salvati',
        "user_entity_preferences": 'Preferenze entità',
        "log": 'Log di sistema',
        "gyv_alert_quotazione": 'Alert',
        "gyv_anagrafica_conto_invio_a20": 'Anagrafica Conto Invio',
        "gyv_anagrafica_conto_invita_amico": 'Codici Amico Usati',
        "gyv_anagrafica_conto_rinnovo": 'Rinnovi',
        "gyv_coupon_conto": 'Coupon',
        "gyv_deposito": 'Depositi',
        "gyv_deposito_periodico": 'Depositi Periodici',
        "gyv_deposito_periodico_frequency": 'Depositi Periodici Frequenza',
        "gyv_deposito_periodico_item": 'Depositi Periodici Elementi',
        "gyv_deposito_rows": 'Depositi Acquisti',
        "gyv_deposito_rows_operation": 'Depositi Conversioni Lingotti',
        "gyv_procacciatori": 'Procacciatori',
        "gyv_quotazioni": 'Quotazioni',
        "gyv_tipo_quotazione": 'Tipologie Quotazioni',
    },
    labels: {
        "user": {
            "id": 'id',
            "deleted": 'Eliminato',
            "an_ID": 'An_id__',
            "an_data_ins": 'An_data_ins__',
            "date": 'Data',
            "an_cf": 'An_cf__',
            "username": 'Username__',
            "an_piva": 'An_piva__',
            "an_sdi": 'An_sdi__',
            "an_nome": 'An_nome__',
            "name": 'Nome',
            "an_cognome": 'An_cognome__',
            "surname": 'Surname__',
            "an_sex": 'An_sex__',
            "an_nasc_dat": 'An_nasc_dat__',
            "an_nasc_com": 'An_nasc_com__',
            "an_nasc_prov": 'An_nasc_prov__',
            "an_nasc_stato": 'An_nasc_stato__',
            "an_cittadinanza": 'An_cittadinanza__',
            "an_mail": 'An_mail__',
            "email": 'Email',
            "idUserGroup": 'Idusergroup__',
            "an_password": 'An_password__',
            "password": 'Password__',
            "an_tel": 'An_tel__',
            "an_cell_prefix": 'An_cell_prefix__',
            "an_cell_num": 'An_cell_num__',
            "an_esp_pol": 'An_esp_pol__',
            "an_co": 'An_co__',
            "an_indirizzo": 'An_indirizzo__',
            "an_nciv": 'An_nciv__',
            "an_cap": 'An_cap__',
            "an_citta": 'An_citta__',
            "an_provincia": 'An_provincia__',
            "an_stato": 'An_stato__',
            "an_doc_tip": 'An_doc_tip__',
            "an_doc_tip.options.CI": 'Ci',
            "an_doc_tip.options.PA": 'Pa',
            "an_doc_tip.options.PD": 'Pd',
            "an_doc_tip.options.PS": 'Ps',
            "an_doc_num": 'An_doc_num__',
            "an_doc_luo_ril": 'An_doc_luo_ril__',
            "an_doc_data_ril": 'An_doc_data_ril__',
            "an_doc_data_scad": 'An_doc_data_scad__',
            "ans_ID": 'Ans_id__',
            "an_sped_diverso": 'An_sped_diverso__',
            "an_sped_co": 'An_sped_co__',
            "an_sped_indirizzo": 'An_sped_indirizzo__',
            "an_sped_nciv": 'An_sped_nciv__',
            "an_sped_cap": 'An_sped_cap__',
            "an_sped_citta": 'An_sped_citta__',
            "an_sped_provincia": 'An_sped_provincia__',
            "an_sped_stato": 'An_sped_stato__',
            "an_sped_telefono": 'An_sped_telefono__',
            "an_rit_terze_parti": 'An_rit_terze_parti__',
            "an_rit_terze_parti.options.0": '0',
            "an_rit_terze_parti.options.1": '1',
            "an_rit_terze_parti_nom": 'An_rit_terze_parti_nom__',
            "an_rit_terze_parti_cog": 'An_rit_terze_parti_cog__',
            "an_rit_terze_parti_ci": 'An_rit_terze_parti_ci__',
            "an_rit_terze_parti_cf": 'An_rit_terze_parti_cf__',
            "an_note": 'An_note__',
            "an_status": 'An_status__',
            "an_mailing": 'An_mailing__',
            "an_mailing_profilazione": 'An_mailing_profilazione__',
            "an_iban": 'An_iban__',
            "an_banca": 'An_banca__',
            "an_conto_intestato": 'An_conto_intestato__',
            "an_pr_ID": 'An_pr_id__',
            "token": 'Token__',
            "an_rotary_club": 'An_rotary_club__',
            "lin_ID": 'Lin_id__',
            "an_last_access": 'An_last_access__',
            "an_access": 'An_access__',
            "an_demo": 'An_demo__',
            "an_follow_fb": 'An_follow_fb__',
            "an_know_timecapsule": 'An_know_timecapsule__',
            "an_know_timecapsule_invio_dat": 'An_know_timecapsule_invio_dat__',
            "an_promo_consulenti": 'An_promo_consulenti__',
            "an_promo_consulenti_scad": 'An_promo_consulenti_scad__',
            "an_promo_consulenti_att": 'An_promo_consulenti_att__',
            "an_reg_oronews": 'An_reg_oronews__',
            "an_reg_blog_ID": 'An_reg_blog_id__',
            "an_configuratore_reg": 'An_configuratore_reg__',
            "an_qtz_riservate": 'An_qtz_riservate__',
            "an_qtz_ven_au": 'An_qtz_ven_au__',
            "an_qtz_ven_ag": 'An_qtz_ven_ag__',
            "an_qtz_ven_pt": 'An_qtz_ven_pt__',
            "an_qtz_ven_pd": 'An_qtz_ven_pd__',
            "an_qtz_acq_au": 'An_qtz_acq_au__',
            "an_qtz_acq_ag": 'An_qtz_acq_ag__',
            "an_qtz_acq_pt": 'An_qtz_acq_pt__',
            "an_qtz_acq_pd": 'An_qtz_acq_pd__',
            "an_reg_coupon": 'An_reg_coupon__',
            "an_hide_info_sens": 'An_hide_info_sens__',
            "an_conto_minore": 'An_conto_minore__',
            "an_conto_minore_genitore_1": 'An_conto_minore_genitore_1__',
            "an_conto_minore_genitore_2": 'An_conto_minore_genitore_2__',
            "an_memo_id_vecchi_union": 'An_memo_id_vecchi_union__',
            "an_documentazione_valida": 'An_documentazione_valida__',
            "an_professione": 'An_professione__',
            "an_disallow_bitage": 'An_disallow_bitage__',
            "an_visabit_reg": 'An_visabit_reg__',
            "an_reg_ip": 'An_reg_ip__',
            "an_last_edit": 'An_last_edit__',
            "lastUpdate": 'Ultimo aggiornamento',
            "an_reset_pwd": 'An_reset_pwd__',
            "an_reset_pwd_token": 'An_reset_pwd_token__',
            "an_reset_pwd_data": 'An_reset_pwd_data__',
            "an_reset_pwd_status": 'An_reset_pwd_status__',
            "an_lista_4dem": 'An_lista_4dem__',
            "an_lista_4dem.options.Orovilla": 'Orovilla',
            "an_lista_4dem.options.Webinar": 'Webinar',
            "an_lista_4dem.options.Consulenti": 'Consulenti',
            "an_lista_4dem.options.Eventi": 'Eventi',
            "an_lista_4dem.options.Oronews": 'Oronews',
            "an_lista_4dem.options. ": ' ',
            "an_fonte_4dem": 'An_fonte_4dem__',
            "bk_25_5_21_an_mailing": 'Bk_25_5_21_an_mailing__',
            "an_complete_profile": 'An_complete_profile__',
            "an_id_front": 'An_id_front__',
            "an_id_rear": 'An_id_rear__',
            "an_cf_front": 'An_cf_front__',
            "an_cf_rear": 'An_cf_rear__',
            "an_a20": 'An_a20__',
            "an_cro": 'An_cro__',
            "an_chiusura_conto": 'An_chiusura_conto__',
            "an_chiusura_conto_data": 'An_chiusura_conto_data__',
            "an_memo_rinnovo_year": 'An_memo_rinnovo_year__',
            "an_memo_rinnovo_data": 'An_memo_rinnovo_data__',
            "an_codice_amico": 'An_codice_amico__',
            "an_reg_odice_amico": 'An_reg_odice_amico__',
            "an_last_edit_admin": 'An_last_edit_admin__',
            "an_dashboard_msg": 'An_dashboard_msg__',
            "an_sped_franco_partenza": 'An_sped_franco_partenza__',
            "an_lav_azienda": 'An_lav_azienda__',
            "an_allow_accu_periodico": 'An_allow_accu_periodico__',
        },
        "user_group": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
        },
        "user_entity_saved_filters": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "name": 'Nome',
            "notes": 'Note',
            "content": 'Content__',
        },
        "user_entity_preferences": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "content": 'Content__',
        },
        "log": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idUser": 'Utente',
            "entity": 'Entità',
            "idItem": 'Iditem__',
            "operation": 'Operazione',
            "operation.options.0_DELETE": '0_delete',
            "operation.options.1_EDIT": '1_edit',
            "operation.options.2_ADD": '2_add',
            "operation.options.3_LOGIN": '3_login',
            "operation.options.4_LOGOUT": '4_logout',
            "notes": 'Note',
        },
        "gyv_alert_quotazione": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "aq_ID": 'Aq_id__',
            "an_ID": 'An_id__',
            "idUser": 'Utente',
            "aq_valore": 'Aq_valore__',
            "aq_type": 'Aq_type__',
            "tq_ID": 'Tq_id__',
            "idTipoQuotazione": 'Idtipoquotazione__',
            "aq_data_ins": 'Aq_data_ins__',
            "aq_process": 'Aq_process__',
            "aq_data_process": 'Aq_data_process__',
            "aq_valore_process": 'Aq_valore_process__',
            "aq_nominativo": 'Aq_nominativo__',
            "aq_email": 'Aq_email__',
        },
        "gyv_anagrafica_conto_invio_a20": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "aci20_id": 'Aci20_id__',
            "an_ID": 'An_id__',
            "idUser": 'Utente',
            "aci20_anno": 'Aci20_anno__',
            "aci20_data": 'Aci20_data__',
            "aci20_send": 'Aci20_send__',
            "aci20_send_data": 'Aci20_send_data__',
            "aci20_send_memo": 'Aci20_send_memo__',
            "aci20_send_memo_data": 'Aci20_send_memo_data__',
            "aci20_doc_ok": 'Aci20_doc_ok__',
            "aci20_doc_iscrizione": 'Aci20_doc_iscrizione__',
        },
        "gyv_anagrafica_conto_invita_amico": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "acia_ID": 'Acia_id__',
            "acia_data_ins": 'Acia_data_ins__',
            "acia_lastmod": 'Acia_lastmod__',
            "an_ID": 'An_id__',
            "idUser": 'Utente',
            "acia_codice_amico": 'Acia_codice_amico__',
            "acia_nome": 'Acia_nome__',
            "acia_email": 'Acia_email__',
            "acia_message": 'Acia_message__',
            "acia_status": 'Acia_status__',
            "acia_status.options.0": '0',
            "acia_status.options.1": '1',
            "acia_status.options.2": '2',
            "rin_ID": 'Rin_id__',
            "idAnagraficaContoRinnovo": 'Idanagraficacontorinnovo__',
            "acia_used": 'Acia_used__',
            "acia_used_rin_ID": 'Acia_used_rin_id__',
        },
        "gyv_anagrafica_conto_rinnovo": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "rin_ID": 'Rin_id__',
            "an_ID": 'An_id__',
            "idUser": 'Utente',
            "rin_data_ins": 'Rin_data_ins__',
            "rin_lastmod": 'Rin_lastmod__',
            "rin_manuale": 'Rin_manuale__',
            "rin_codice": 'Rin_codice__',
            "rind_data_da": 'Rind_data_da__',
            "rind_data_a": 'Rind_data_a__',
            "rin_importo": 'Rin_importo__',
            "rin_pagato": 'Rin_pagato__',
            "rin_fattura": 'Rin_fattura__',
            "rin_mail": 'Rin_mail__',
            "rin_mail_data": 'Rin_mail_data__',
            "rin_mail_pagato": 'Rin_mail_pagato__',
            "rin_mail_pagato_data": 'Rin_mail_pagato_data__',
            "rin_bitage": 'Rin_bitage__',
            "rin_bitage.options.0": '0',
            "rin_bitage.options.1": '1',
            "rin_bitage.options.2": '2',
            "rin_bitage_data": 'Rin_bitage_data__',
            "rin_bitage_ret": 'Rin_bitage_ret__',
            "rin_omaggio_iniziale": 'Rin_omaggio_iniziale__',
        },
        "gyv_coupon_conto": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "cc_ID": 'Cc_id__',
            "cc_data_ins": 'Cc_data_ins__',
            "cc_lastmod": 'Cc_lastmod__',
            "cc_code": 'Cc_code__',
            "cc_dal": 'Cc_dal__',
            "cc_al": 'Cc_al__',
            "cc_sconto_perc": 'Cc_sconto_perc__',
            "cc_cumulabile": 'Cc_cumulabile__',
            "cc_status": 'Cc_status__',
            "cc_pr_ID": 'Cc_pr_id__',
        },
        "gyv_deposito": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "d_ID": 'D_id__',
            "d_codice": 'D_codice__',
            "d_qtz_au": 'D_qtz_au__',
            "d_qtz_ag": 'D_qtz_ag__',
            "d_qtz_pt": 'D_qtz_pt__',
            "d_qtz_pd": 'D_qtz_pd__',
            "pr_qtz_au": 'Pr_qtz_au__',
            "pr_qtz_ag": 'Pr_qtz_ag__',
            "pr_qtz_pt": 'Pr_qtz_pt__',
            "pr_qtz_pd": 'Pr_qtz_pd__',
            "pr_qtz_au_ric": 'Pr_qtz_au_ric__',
            "pr_qtz_ag_ric": 'Pr_qtz_ag_ric__',
            "pr_qtz_pt_ric": 'Pr_qtz_pt_ric__',
            "pr_qtz_pd_ric": 'Pr_qtz_pd_ric__',
            "an_ID": 'An_id__',
            "idUser": 'Utente',
            "d_data": 'D_data__',
            "d_info_utente": 'D_info_utente__',
            "d_ric_pre": 'D_ric_pre__',
            "d_conf_ord": 'D_conf_ord__',
            "d_sol_bb": 'D_sol_bb__',
            "d_conf_bb": 'D_conf_bb__',
            "d_dol_doc": 'D_dol_doc__',
            "d_concluso": 'D_concluso__',
            "d_annullato": 'D_annullato__',
            "d_annullato_tipo": 'D_annullato_tipo__',
            "d_err_mail": 'D_err_mail__',
            "d_conf_ord_data": 'D_conf_ord_data__',
            "d_sol_doc_data": 'D_sol_doc_data__',
            "d_conf_bb_data": 'D_conf_bb_data__',
            "d_concluso_data": 'D_concluso_data__',
            "d_annullato_data": 'D_annullato_data__',
            "d_nr_fattura": 'D_nr_fattura__',
            "d_ritiro": 'D_ritiro__',
            "d_iban": 'D_iban__',
            "d_banca": 'D_banca__',
            "d_conto_intestato": 'D_conto_intestato__',
            "pr_ID": 'Pr_id__',
            "idProcacciatori": 'Idprocacciatori__',
            "pr_ID_24_02_21": 'Pr_id_24_02_21__',
            "d_da_admin": 'D_da_admin__',
            "d_da_partner": 'D_da_partner__',
            "d_da_timecapsule": 'D_da_timecapsule__',
            "d_da_periodico": 'D_da_periodico__',
            "dp_ID": 'Dp_id__',
            "idDepositoPeriodico": 'Iddepositoperiodico__',
            "d_bollo": 'D_bollo__',
            "d_practor": 'D_practor__',
            "d_practor_data": 'D_practor_data__',
            "d_practor_ret": 'D_practor_ret__',
            "d_practor_text": 'D_practor_text__',
            "d_bitage": 'D_bitage__',
            "d_bitage.options.0": '0',
            "d_bitage.options.1": '1',
            "d_bitage.options.2": '2',
            "d_bitage.options.3": '3',
            "d_bitage_data": 'D_bitage_data__',
            "d_bitage_ret": 'D_bitage_ret__',
            "d_bitage_status": 'D_bitage_status__',
            "d_lin_ID": 'D_lin_id__',
            "d_fatturazione": 'D_fatturazione__',
        },
        "gyv_deposito_periodico": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "dp_ID": 'Dp_id__',
            "pr_ID": 'Pr_id__',
            "idProcacciatori": 'Idprocacciatori__',
            "pr_sovrapz": 'Pr_sovrapz__',
            "dp_tipo": 'Dp_tipo__',
            "dp_tipo.options.AU": 'Au',
            "dp_tipo.options.AG": 'Ag',
            "dp_tipo.options.PT": 'Pt',
            "dp_tipo.options.PD": 'Pd',
            "dpf_ID": 'Dpf_id__',
            "idDepositoPeriodicoFrequency": 'Iddepositoperiodicofrequency__',
            "dp_importo": 'Dp_importo__',
            "dp_descr": 'Dp_descr__',
            "dp_data_ins": 'Dp_data_ins__',
            "dp_data_start": 'Dp_data_start__',
            "dp_status": 'Dp_status__',
            "dp_status_data": 'Dp_status_data__',
            "dp_status_note": 'Dp_status_note__',
            "an_ID": 'An_id__',
            "idUser": 'Utente',
        },
        "gyv_deposito_periodico_frequency": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "dpf_ID": 'Dpf_id__',
            "dpf_label": 'Dpf_label__',
            "dpf_add_val_str": 'Dpf_add_val_str__',
            "dpf_add_val_num": 'Dpf_add_val_num__',
        },
        "gyv_deposito_periodico_item": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "dpi_ID": 'Dpi_id__',
            "dp_ID": 'Dp_id__',
            "dpi_date": 'Dpi_date__',
            "dpi_date_original": 'Dpi_date_original__',
            "dpi_eseguito": 'Dpi_eseguito__',
            "dpi_eseguito_data": 'Dpi_eseguito_data__',
            "d_ID": 'D_id__',
            "idDeposito": 'Iddeposito__',
        },
        "gyv_deposito_rows": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "r_ID": 'R_id__',
            "d_ID": 'D_id__',
            "idDeposito": 'Iddeposito__',
            "r_nome": 'R_nome__',
            "r_tipo": 'R_tipo__',
            "r_tipo.options.AU": 'Au',
            "r_tipo.options.AG": 'Ag',
            "r_tipo.options.PT": 'Pt',
            "r_tipo.options.PD": 'Pd',
            "r_peso": 'R_peso__',
            "r_qtz": 'R_qtz__',
            "r_qtz_web": 'R_qtz_web__',
            "r_iva": 'R_iva__',
            "r_valore": 'R_valore__',
            "r_era_zero": 'R_era_zero__',
            "r_timestamp": 'R_timestamp__',
        },
        "gyv_deposito_rows_operation": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "dro_ID": 'Dro_id__',
            "dpf_label": 'Dpf_label__',
            "dro_order": 'Dro_order__',
            "dro_lin_ID": 'Dro_lin_id__',
            "dro_order_ritiro": 'Dro_order_ritiro__',
            "dro_order_payment": 'Dro_order_payment__',
            "dro_type": 'Dro_type__',
            "pr_ID": 'Pr_id__',
            "idProcacciatori": 'Idprocacciatori__',
            "pr_nome": 'Pr_nome__',
            "pr_qta": 'Pr_qta__',
            "pr_peso": 'Pr_peso__',
            "an_ID": 'An_id__',
            "idUser": 'Utente',
            "procacciatore_ID": 'Procacciatore_id__',
            "dro_tip_qtz": 'Dro_tip_qtz__',
            "dro_tip_qtz.options.AU": 'Au',
            "dro_tip_qtz.options.AG": 'Ag',
            "dro_tip_qtz.options.PT": 'Pt',
            "dro_tip_qtz.options.PD": 'Pd',
            "dro_tot_gr": 'Dro_tot_gr__',
            "dro_price": 'Dro_price__',
            "dro_order_lavorazione": 'Dro_order_lavorazione__',
            "dro_order_spedizione": 'Dro_order_spedizione__',
            "dro_order_iva": 'Dro_order_iva__',
            "dro_order_row_total": 'Dro_order_row_total__',
            "dro_order_total": 'Dro_order_total__',
            "dro_order_commissioni": 'Dro_order_commissioni__',
            "dro_order_status": 'Dro_order_status__',
            "dro_order_status.options.0": '0',
            "dro_order_status.options.1": '1',
            "dro_order_status.options.2": '2',
            "dro_order_status.options.3": '3',
            "dro_order_status.options.4": '4',
            "dro_data": 'Dro_data__',
            "dro_ric_pre": 'Dro_ric_pre__',
            "dro_conf_ord": 'Dro_conf_ord__',
            "dro_sol_bb": 'Dro_sol_bb__',
            "dro_conf_bb": 'Dro_conf_bb__',
            "dro_sol_doc": 'Dro_sol_doc__',
            "dro_concluso": 'Dro_concluso__',
            "dro_annullato": 'Dro_annullato__',
            "dro_conf_sport": 'Dro_conf_sport__',
            "dro_conf_sport_dat": 'Dro_conf_sport_dat__',
            "dro_qtz_au": 'Dro_qtz_au__',
            "dro_qtz_ag": 'Dro_qtz_ag__',
            "dro_qtz_pt": 'Dro_qtz_pt__',
            "dro_qtz_pd": 'Dro_qtz_pd__',
            "dro_web_qtz_au": 'Dro_web_qtz_au__',
            "dro_web_qtz_ag": 'Dro_web_qtz_ag__',
            "dro_web_qtz_pt": 'Dro_web_qtz_pt__',
            "dro_web_qtz_pd": 'Dro_web_qtz_pd__',
        },
        "gyv_procacciatori": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "pr_ID": 'Pr_id__',
            "prUsername": 'Prusername__',
            "pr_nome": 'Pr_nome__',
            "pr_code": 'Pr_code__',
            "pr_code_fatt": 'Pr_code_fatt__',
            "pr_password": 'Pr_password__',
            "pr_cf": 'Pr_cf__',
            "pr_piva": 'Pr_piva__',
            "pr_indirizzo": 'Pr_indirizzo__',
            "pr_descr_contatti": 'Pr_descr_contatti__',
            "pr_cap": 'Pr_cap__',
            "pr_city": 'Pr_city__',
            "pr_prov": 'Pr_prov__',
            "pr_tel": 'Pr_tel__',
            "pr_email": 'Pr_email__',
            "pr_dat_ini_contr": 'Pr_dat_ini_contr__',
            "pr_dat_sca_contr": 'Pr_dat_sca_contr__',
            "pr_dat_sca_conto_visione": 'Pr_dat_sca_conto_visione__',
            "pr_contr_gabri": 'Pr_contr_gabri__',
            "pr_note": 'Pr_note__',
            "pr_cvisione": 'Pr_cvisione__',
            "pr_ddt_data": 'Pr_ddt_data__',
            "pr_stato": 'Pr_stato__',
            "pr_val_compro": 'Pr_val_compro__',
            "pr_val_compro_ag": 'Pr_val_compro_ag__',
            "pr_val_compro_pt": 'Pr_val_compro_pt__',
            "pr_ricarico_compro_pt": 'Pr_ricarico_compro_pt__',
            "pr_ricarico_compro_pd": 'Pr_ricarico_compro_pd__',
            "pr_ricarico_compro": 'Pr_ricarico_compro__',
            "pr_ricarico_compro_ag": 'Pr_ricarico_compro_ag__',
            "pr_val_vendo": 'Pr_val_vendo__',
            "pr_val_vendo_ag": 'Pr_val_vendo_ag__',
            "pr_val_vendo_pt": 'Pr_val_vendo_pt__',
            "pr_val_vendo_pd": 'Pr_val_vendo_pd__',
            "pr_ricarico_vendo": 'Pr_ricarico_vendo__',
            "pr_ricarico_vendo_ag": 'Pr_ricarico_vendo_ag__',
            "pr_ricarico_vendo_pt": 'Pr_ricarico_vendo_pt__',
            "pr_ricarico_vendo_pd": 'Pr_ricarico_vendo_pd__',
            "pr_cap_area": 'Pr_cap_area__',
            "pr_show_site": 'Pr_show_site__',
            "pr_lat": 'Pr_lat__',
            "pr_long": 'Pr_long__',
            "pr_agente": 'Pr_agente__',
            "pr_riepilogo_condizioni": 'Pr_riepilogo_condizioni__',
            "pr_rjc": 'Pr_rjc__',
            "pr_agente_nome": 'Pr_agente_nome__',
            "pr_leg": 'Pr_leg__',
            "pr_super": 'Pr_super__',
            "token": 'Token__',
            "pr_last_edit": 'Pr_last_edit__',
            "pr_rotary_on": 'Pr_rotary_on__',
            "pr_azienda": 'Pr_azienda__',
            "pr_subazienda": 'Pr_subazienda__',
            "pr_consulente": 'Pr_consulente__',
            "pr_promo_consulente": 'Pr_promo_consulente__',
            "pr_demo": 'Pr_demo__',
            "pr_lin_ID": 'Pr_lin_id__',
            "pr_operazioni_area": 'Pr_operazioni_area__',
            "pr_vetrofonia": 'Pr_vetrofonia__',
            "pr_tagliandi_sconto": 'Pr_tagliandi_sconto__',
            "pr_conto_visione": 'Pr_conto_visione__',
            "pr_pickpay": 'Pr_pickpay__',
            "pr_report_prov_excl": 'Pr_report_prov_excl__',
        },
        "gyv_quotazioni": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "QUO_ID": 'Quo_id__',
            "QUO_AU_ACQ": 'Quo_au_acq__',
            "QUO_AU_VEN": 'Quo_au_ven__',
            "QUO_AG_ACQ": 'Quo_ag_acq__',
            "QUO_AG_VEN": 'Quo_ag_ven__',
            "QUO_PT_ACQ": 'Quo_pt_acq__',
            "QUO_PT_VEN": 'Quo_pt_ven__',
            "QUO_PD_ACQ": 'Quo_pd_acq__',
            "QUO_PD_VEN": 'Quo_pd_ven__',
            "QUO_PRO_AU": 'Quo_pro_au__',
            "QUO_PRO_AG": 'Quo_pro_ag__',
            "QUO_PRO_PT": 'Quo_pro_pt__',
            "QUO_PRO_PD": 'Quo_pro_pd__',
            "QUO_DATA": 'Quo_data__',
            "QUO_SAVE": 'Quo_save__',
            "QUO_AU": 'Quo_au__',
            "QUO_AG": 'Quo_ag__',
            "QUO_PT": 'Quo_pt__',
            "QUO_PD": 'Quo_pd__',
            "QUO_RU": 'Quo_ru__',
            "QUO_EUR_DOL": 'Quo_eur_dol__',
            "QUO_EUR_GBP": 'Quo_eur_gbp__',
            "QUO_EUR_CHF": 'Quo_eur_chf__',
            "QUO_AU_DOLCONCIA": 'Quo_au_dolconcia__',
            "QUO_FIX_DATA": 'Quo_fix_data__',
            "QUO_FIX_AU_AM": 'Quo_fix_au_am__',
            "QUO_FIX_AU_PM": 'Quo_fix_au_pm__',
            "QUO_FIX_AG_AM": 'Quo_fix_ag_am__',
            "QUO_FIX_AG_PM": 'Quo_fix_ag_pm__',
            "QUO_FIX_PT_AM": 'Quo_fix_pt_am__',
            "QUO_FIX_PT_PM": 'Quo_fix_pt_pm__',
            "QUO_FIX_PD_AM": 'Quo_fix_pd_am__',
            "QUO_FIX_PD_PM": 'Quo_fix_pd_pm__',
            "QUO_FIXE_AU_AM": 'Quo_fixe_au_am__',
            "QUO_FIXE_AU_PM": 'Quo_fixe_au_pm__',
            "QUO_FIXE_AG_AM": 'Quo_fixe_ag_am__',
            "QUO_FIXE_AG_PM": 'Quo_fixe_ag_pm__',
            "QUO_FIXE_PT_AM": 'Quo_fixe_pt_am__',
            "QUO_FIXE_PT_PM": 'Quo_fixe_pt_pm__',
            "QUO_FIXE_PD_AM": 'Quo_fixe_pd_am__',
            "QUO_FIXE_PD_PM": 'Quo_fixe_pd_pm__',
        },
        "gyv_tipo_quotazione": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "tq_ID": 'Tq_id__',
            "tq_nome": 'Tq_nome__',
            "tq_nome_en": 'Tq_nome_en__',
            "tq_ord": 'Tq_ord__',
            "tq_iva": 'Tq_iva__',
            "margine_ovi": 'Margine_ovi__',
            "margine_procacciatore": 'Margine_procacciatore__',
        },
    }
};