import logo from './logo.svg';

export const settings = {
    "title": "Orovilla Backoffice",
    "apiUrl": "https://app.orovilla.com/api",
    "ckdPath": "https://app.orovilla.com/",
    "tinyKey": "u2y9r6cw0e1erg33drzg4fs0swwi1zrs85bcin3ynrsxlwz2",
    "googleMapKey": "inserire_chiave_valida",
    "config": {
        "limit": 100,
        "sortingField": 'id',
        "sortingOrder": 'desc'
    },
    "theme": {
        "siderbarIconColor": 'rgba(233,236,239,.5)'
    },
    "locale": {
        "currency": 'EUR',
        "country": 'it-IT'
    },
    "logo": logo
}